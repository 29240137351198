<template>
    <section class="">   
        <v-layout justify-space-around justify-center class="support-layout">
            <v-flex xs9 sm9 offset-sm0 class="">
                    <v-card flat tile class="transparent" color="" height="100%"  column align="center" justify="center"   >
                         <v-card-text class="white--text subheading mb-3 text-xs-center parallax-text support-header">
                            <span  v-if="routeName === 'support' "  class="slider-about-header"><span class="slider-subheader zamu-heading ">Contact </span><span class="zamu-normal"> Us</span> </span>
                            <span v-else class="slider-about-header"><span class="slider-subheader zamu-heading ">Frequently </span><span class="zamu-normal">Asked Questions</span> </span>

                        </v-card-text>
                    </v-card>
            </v-flex>
        </v-layout>
    </section>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';

export default {
    mixins: [coreMixin],


}
</script>

<style>

</style>